export const FORM_LAYOUT_CONTROLS_KEY = "FormLayoutControls";
export const FORM_LAYOUT_DATA_SOURCES_KEY = "FormLayoutDataSources";
export const FORM_LAYOUT_NAME_KEY = "Name";
export const FORM_LAYOUT_CREATED_DATE_TIME_KEY = "CreatedDateTime";
export const FORM_LAYOUT_DESCRIPTION_KEY = "Description";
export const FORM_LAYOUT_CUSTOM_BACKGROUND_COLOR_KEY = "CustomBackgroundColor";
export const FORM_LAYOUT_BACKGROUND_IMAGE_DISPLAY_NAME_KEY = "BackgroundImageDisplayName";
export const FORM_LAYOUT_SETTINGS_JSON_KEY = "SettingsJson";
export const FORM_LAYOUT_STYLES_JSON_KEY = "StylesJson";
export const FORM_LAYOUT_HEIGHT = "Height";
export const FORM_LAYOUT_WIDTH = "Width";
export const FORM_LAYOUT_TAB_POSITION = "TabPosition";
export const FORM_LAYOUT_LOCKED = "Locked";
export const FORM_LAYOUT_ALIAS = "Alias";
export const FORM_LAYOUT_PRODUCT_ROOT_ID = "ProductRootId";
export const FORM_LAYOUT_ORGANIZATION_ROOT_ID = "OrganizationRootId";
export const FORM_LAYOUT_CREATED_BY_USER_ROOT_ID = "CreatedByUserRootId";
export const FORM_LAYOUT_FORMATTED_OWNER = "FormattedOwner";
export const FORM_LAYOUT_LOCATION_DISPLAY_NAME_KEY = "FormLocationDisplayName";
export const FORM_LAYOUT_NAVIGATION_FORM_GROUP_ROOT_ID = "FormGroupRootId";
export const FORM_LAYOUT_GROUPS_ITEMS = "FormNavigationTree";
export const FORM_LAYOUT_IS_DRAFT = "Draft";

export const FORM_LAYOUT_SHOW_LABELS_KEY = "ShowLabels";

export const FORM_LAYOUT_MARGIN_X = "MarginX";
export const FORM_LAYOUT_MARGIN_Y = "MarginY";
export const FORM_LAYOUT_ALLOW_CANCEL_KEY = "CanCancel";
export const FORM_LAYOUT_ALLOW_SAVING_KEY = "CanSave";
export const FORM_LAYOUT_LOCATION_ROOT_ID_KEY = "FormLocationRootId";
export const FORM_LAYOUT_GRID_LINES_KEY = "GridLines";

export const FORM_LAYOUT_JUST_UPDATE_GROUP_AND_POSITION_KEY = "UpdateFormGroupRootIdAndTabPositionOnly";

// layout settings
export function getFormDataFilters(formLayout, DataSourceRecords) {
  const dataSourceSearches = formLayout?.[FORM_LAYOUT_DATA_SOURCES_KEY];
  if (!dataSourceSearches) return [];
  const formDataFilters = [];
  dataSourceSearches.forEach((search) => {
    search?.Search?.SearchFilters?.forEach?.((searchFilter) => {
      let RecordRootId = DataSourceRecords.find((record) => record?.OrganizationDataSourceRootId === searchFilter?.OrganizationDataSourceRootId)?.RecordRootId;
      if (RecordRootId > 0) {
        formDataFilters.push({
          ProductDataSourceRootId: Number(searchFilter?.ProductDataSourceRootId ?? 0),
          ProductDataSourceFieldRootId: Number(searchFilter?.ProductDataSourceFieldRootId ?? 0),
          OrganizationDataSourceRootId: Number(searchFilter?.OrganizationDataSourceRootId ?? 0),
          OrganizationDataSourceFieldRootId: Number(searchFilter?.OrganizationDataSourceFieldRootId ?? 0),
          RecordRootId,
        });
      } else {
        let RecordRootId = DataSourceRecords.find((record) => record?.ProductDataSourceRootId === searchFilter?.ProductDataSourceRootId)?.RecordRootId;
        if (RecordRootId > 0) {
          formDataFilters.push({
            ProductDataSourceRootId: Number(searchFilter?.ProductDataSourceRootId ?? 0),
            ProductDataSourceFieldRootId: Number(searchFilter?.ProductDataSourceFieldRootId ?? 0),
            OrganizationDataSourceRootId: Number(searchFilter?.OrganizationDataSourceRootId ?? 0),
            OrganizationDataSourceFieldRootId: Number(searchFilter?.OrganizationDataSourceFieldRootId ?? 0),
            RecordRootId,
          });
        }
      }
    });
  });
  return formDataFilters;
}
